import { InteractiveTrip, safeTimeSlice } from "@chartedsails/tracks";
import { isNotNullish } from "~/components/util/isNotNullish";
import { Media } from "../../../backend/graphql/Media";
import { SailingMark } from "../../../backend/graphql/SailingMark";
import { Segment } from "../../../backend/graphql/Segment";
import { isRaceSegment } from "../../../model/RaceSegment";
import { isVideoMedia } from "../../../model/VideoMedia";
import { raceWithDynamicMarksFrozen } from "../marks/dynamic-marks";
import { calculateSpeedScaleMax } from "../speedgradient/calculate-speed-scale-max";
import { createReplaySailingMarks } from "./createReplaySailingMarks";
import { replayMakeAllTracksFitView } from "./helpers/makeAllTracksFitView";
import {
  animateRotation,
  replayWithSessionEdit,
  updatePlaybackAnimation,
} from "./reducer-helpers";
import { Replay } from "./Replay";

export type ReplaySessionAction =
  | {
      event: "session-boats-changed";
      boats: Replay["boats"];
      isLiveTracking: boolean;
      initialWorldToBoatsAnimation: boolean;
    }
  | {
      event: "session-bounds-changed";
      startTime: number;
      endTime: number;
      earliestData: number;
      latestData: number;
    }
  | { event: "session-media-change"; media: Media[] }
  | {
      event: "session-segments-or-marks-change";
      segments: Segment[];
      sessionMarks: SailingMark[];
      trips: Map<string, InteractiveTrip>;
    }
  | { event: "session-editable-changed"; editable: boolean | undefined }
  | { event: "sessionediting-trim-session"; bounds: [number, number] }
  | { event: "sessionediting-addboat-start" }
  | { event: "sessionediting-start" }
  | { event: "sessionediting-save" }
  | { event: "sessionediting-cancel" }
  | { event: "session-edit-saved" };

export const sessionEventReducer = (
  replay: Replay,
  action: ReplaySessionAction
): Replay => {
  switch (action.event) {
    // Session
    case "session-boats-changed":
      const existingBoatsWithData = replay.boats
        .map((b) => b.data)
        .filter(isNotNullish);
      const newBoatsWithData = action.boats
        .map((b) => b.data)
        .filter(isNotNullish);

      const updatedReplay: Replay = {
        ...replay,
        boats: action.boats,
        visibleBoats: action.boats.map((b) => b.id),
        initialZoomToBoatsScheduled: newBoatsWithData.length > 0,
      };

      // If we are live tracking, do not change zoom or recalculate speedscalemax
      // when data changes but not the list of boats
      if (
        action.isLiveTracking &&
        existingBoatsWithData.length === newBoatsWithData.length
      ) {
        return updatedReplay;
      }
      // Skip the initial animation if requested - but always animate later on.
      const animateToBoats = replay.initialZoomToBoatsScheduled
        ? true
        : action.initialWorldToBoatsAnimation;
      return replayMakeAllTracksFitView(
        updatedReplay,
        animateToBoats ? 1500 : 0
      );
    case "session-bounds-changed":
      if (replay.activePane === "trimming") {
        return replay;
      }
      let newPlaybackTime = Math.max(action.startTime, replay.playbackTime);
      return {
        ...replay,
        earliestDataAvailable: action.earliestData,
        latestDataAvailable: action.latestData,
        startTime: action.startTime,
        endTime: action.endTime,
        speedScaleMax: calculateSpeedScaleMax(
          replay.boats
            .map((b) => b.data)
            .filter(isNotNullish)
            .map((trip) =>
              safeTimeSlice(trip, action.startTime, action.endTime)
            )
            .filter(isNotNullish)
        ),
        playbackTime: newPlaybackTime,
        playing: false,
      };
    case "session-editable-changed":
      return {
        ...replay,
        editable: action.editable,
      };
    case "sessionediting-addboat-start":
      return { ...replay, hover: undefined, playing: false };
    case "sessionediting-save":
      return {
        ...replay,
        activePane: "replay-chart",
        analysisTab: "performance",
        hover: undefined,
        playing: false,
      };
    case "sessionediting-start":
      return animateRotation(
        {
          ...replay,
          activePane: "trimming",
          hover: undefined,
          playing: false,
          activeSegment: undefined,
          timeSelection: undefined,
        },
        replay.sessionTrueWindDirection ?? 0
      );
    case "sessionediting-trim-session": {
      let r = {
        ...replay,
        startTime: action.bounds[0],
        endTime: action.bounds[1],
      };
      r.speedScaleMax = calculateSpeedScaleMax(
        replay.boats
          .map((b) => b.data)
          .filter(isNotNullish)
          .map((trip) => safeTimeSlice(trip, r.startTime, r.endTime))
          .filter(isNotNullish)
      );
      r = updatePlaybackAnimation(r, replay.playbackTime);
      return replayWithSessionEdit(r, {
        startTime: new Date(r.startTime).toISOString(),
        endTime: new Date(r.endTime).toISOString(),
      });
    }
    case "session-edit-saved":
      return { ...replay, pendingEdit: undefined };
    case "session-media-change":
      const media = action.media.filter(isVideoMedia);
      // Update the active video
      const activeVideo = replay.activeVideo
        ? media.find((m) => m.id === replay.activeVideo?.id)
        : undefined;

      return { ...replay, media, activeVideo };
    case "session-segments-or-marks-change":
      const sailingMarks = createReplaySailingMarks(
        action.sessionMarks,
        action.trips
      );
      const segments = action.segments
        .map((s) => {
          if (isRaceSegment(s)) {
            try {
              return raceWithDynamicMarksFrozen(s, sailingMarks);
            } catch (e) {
              // Remove races if they are missing data.
              // Notably this happens until data is loaded.
              return null;
            }
          } else {
            return s;
          }
        })
        .filter(isNotNullish);
      return { ...replay, segments: segments, sailingMarks };
  }
  return replay;
};

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DaypassType {
  DAYPASS = "DAYPASS",
  FREEFLEXDAY = "FREEFLEXDAY",
  MOBILEBETA = "MOBILEBETA",
}

export enum MediaTimeOriginType {
  FIT_TO_SESSION = "FIT_TO_SESSION",
  SOURCE = "SOURCE",
  USER_SYNCED = "USER_SYNCED",
}

export enum RaceGateType {
  DOWNWIND_BUOY = "DOWNWIND_BUOY",
  DOWNWIND_LINE = "DOWNWIND_LINE",
  FINISH_LINE = "FINISH_LINE",
  GATE = "GATE",
  MARK = "MARK",
  START_LINE = "START_LINE",
  UPWIND_BUOY = "UPWIND_BUOY",
  UPWIND_LINE = "UPWIND_LINE",
  WING_BUOY = "WING_BUOY",
}

export enum SailingMarkType {
  PINGED = "PINGED",
  TRACKED = "TRACKED",
}

export enum SessionUserRole {
  ADMIN = "ADMIN",
  PARTICIPANT = "PARTICIPANT",
  SPECTATOR = "SPECTATOR",
}

export enum SubscriptionPlan {
  ANNUAL = "ANNUAL",
  COACH = "COACH",
  FLEET = "FLEET",
  MONTHLY = "MONTHLY",
  PRO2020 = "PRO2020",
}

export enum SubscriptionStatus {
  Active = "Active",
  Canceling = "Canceling",
  NeedAction = "NeedAction",
  NeedNewPayment = "NeedNewPayment",
  NeedPaymentInfo = "NeedPaymentInfo",
  OtherError = "OtherError",
  Trialing = "Trialing",
}

/**
 * Input info to add a new media
 */
export interface AddSessionMediaInput {
  sessionId: string;
  id: string;
  time: GQLTimestamp;
  timeOrigin: MediaTimeOriginType;
  sourceFilename: string;
  sourceMediaType: string;
  sourceSize: number;
  sourceDetectedTime: GQLTimestamp;
  sourceDetectedTimeSource: string;
  sourceAdjustableTime: boolean;
  sourceName?: string | null;
  videoDuration?: number | null;
  newName?: string | null;
}

/**
 * Editable fields on a session
 */
export interface EditSessionInput {
  id: string;
  title?: string | null;
  startTime?: GQLDateTime | null;
  endTime?: GQLDateTime | null;
  mapOrientation?: number | null;
  marks?: SailingMarkInput[] | null;
  segments?: SegmentInput[] | null;
}

/**
 * Input info to add a new media
 */
export interface EditSessionMediaInput {
  id: string;
  time: GQLTimestamp;
  timeOrigin: MediaTimeOriginType;
}

export interface GarminLinkEdit {
  id: string;
  boatId?: string | null;
}

export interface LiveSessionConfig {
  name?: string | null;
  duration?: number | null;
  trackers: string[];
}

export interface PasswordResetInput {
  password: string;
  token: string;
}

export interface RaceConfigInput {
  gunTime: GQLTimestampEmbedded;
  marks: SailingMarkInput[];
  gates: RaceGateInput[];
}

export interface RaceGateInput {
  type: RaceGateType;
  markId: string;
  secondMarkId?: string | null;
}

export interface SailingMarkInput {
  id?: string | null;
  latitude: number;
  longitude: number;
  type?: SailingMarkType | null;
}

export interface SegmentInput {
  id: string;
  title?: string | null;
  startTime: GQLTimestampEmbedded;
  endTime: GQLTimestampEmbedded;
  trueWindDirection?: number | null;
  raceConfig?: RaceConfigInput | null;
}

export interface SignupInput {
  password: string;
  name: string;
  email: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

import { InteractiveTrip } from "@chartedsails/tracks";
import { SessionInfo } from "~/backend/graphql/SessionInfo";
import { isNotNullish } from "~/components/util/isNotNullish";
import { trackColorsScheme } from "~/styles/chartedSailsTheme";
import { isVideoMedia } from "../../../model/VideoMedia";
import { ReplayMapStyle, TailMode } from "../map/ReplayMap";
import { DEFAULT_SPEED_SCALE_MAX } from "../speedgradient/calculate-speed-scale-max";
import { createReplayBoats } from "./createReplayBoats";
import { createReplaySailingMarks } from "./createReplaySailingMarks";
import { Replay, ReplayBoat, SessionEventWithTypedTrackEvent } from "./Replay";

export const replayDefaultValues = {
  earliestDataAvailable: 0,
  latestDataAvailable: 0,
  boats: [],
  playbackTime: 0,
  visibleBoats: [],

  activePane: "replay-chart" as const,
  analysisTab: "instruments" as "instruments",
  events: [],
  playing: false,
  playbackAnimationStartPosition: 0,
  playbackAnimationStartTime: 0,
  playbackSpeed: 10,
  selectedTailMode: "solid" as TailMode,
  mapStyle: "map" as ReplayMapStyle,
  segments: [],
  viewState: { latitude: 37, longitude: -119, zoom: 5 },
  speedScaleMax: DEFAULT_SPEED_SCALE_MAX,
  initialZoomToBoatsScheduled: false,
  initialZoomToBoatsRequested: false,
  sailingMarks: [],
  media: [],
};

const parseTimeMaybe = (t: string | undefined) =>
  isNotNullish(t) ? Date.parse(t) : 0;

export const createReplayContextFromSession = (
  session: SessionInfo | null,
  trips?: Map<string, InteractiveTrip>
): Replay => {
  const tailMode =
    session && session.boats.length <= 1
      ? "speed-gradient"
      : session && session.boats.length >= 3
        ? "fading-tail"
        : "solid";
  const boats = createReplayBoats(session?.boats ?? [], trips);
  const sailingMarks = createReplaySailingMarks(session?.marks ?? [], trips)

  let replay: Replay = {
    ...replayDefaultValues,
    startTime: parseTimeMaybe(session?.startTime),
    endTime: parseTimeMaybe(session?.endTime),
    earliestDataAvailable: parseTimeMaybe(
      session?.earliestData ?? session?.startTime
    ),
    latestDataAvailable: parseTimeMaybe(
      session?.latestData ?? session?.endTime
    ),
    playbackTime: parseTimeMaybe(session?.startTime),
    selectedTailMode: tailMode,
    sessionTrueWindDirection: session?.mapOrientation ?? undefined,
    segments: session?.segments ?? [],
    viewState: { latitude: 0, longitude: 0, zoom: 1 },
    boats,
    visibleBoats: boats.map((b) => b.id),
    events: (session?.events as SessionEventWithTypedTrackEvent[]) ?? [],
    media: (session?.media ?? []).filter(isVideoMedia),
    sailingMarks
  };

  // When there is at least one race, set the timer on the start time
  const races = session?.segments.filter((s) => s.raceConfig) ?? [];
  if (races.length > 0) {
    replay.playbackTime = races[0].raceConfig?.gunTime ?? replay.playbackTime;
  }
  return replay;
};

export const createReplayContextFromReplayTrip = (
  trip: InteractiveTrip | undefined,
  {
    boatColor,
    boatName,
    initialReplay,
  }: {
    boatColor?: string;
    boatName?: string;
    initialReplay?: Partial<Replay>;
  } = {}
) => {
  const boat: ReplayBoat = {
    color: boatColor ?? trackColorsScheme[0],
    name: boatName ?? "",
    data: trip,
    id: "oneboat",
  };
  const newReplay: Replay = {
    ...replayDefaultValues,
    ...(initialReplay as Replay),
    startTime: trip ? trip.startTime : Date.now(),
    endTime: trip ? trip.endTime : Date.now(),
    boats: [boat],
    visibleBoats: [boat.id],
    playbackTime: trip ? trip.startTime : Date.now(),
  };
  return newReplay;
};

import { useApolloClient } from "@apollo/client";
import { InteractiveTrip } from "@chartedsails/tracks";
import { useEffect, useReducer, useRef } from "react";
import { AnalyticsProperties } from "~/backend/analytics/AnalyticsProperties";
import { PerfTimer } from "~/backend/utils/PerfTimer";
import { log } from "~/util/devconsole";
import { analytics } from "../../analytics/analytics";
import { fetchAndPrepareSessionData } from "../track/fetchAndPrepareSessionData";

interface UseSessionDataState {
  trips?: Map<string, InteractiveTrip>;
  availableDataStart?: number;
  availableDataEnd?: number;
  loading: boolean;
  error?: string;
}

interface StateUpdate {
  event: "LOADING" | "LOADED" | "ALREADY_LOADED" | "ERROR";
  payload?: Partial<UseSessionDataState>;
  errorMessage?: string;
}

export default function useSessionData({
  id,
  startTime,
  endTime,
  boatIds,
  editable,
  filterGpsPointsAboveSOGKts,
}: {
  id: string;
  startTime: string;
  endTime: string;
  boatIds: string[];
  editable: boolean;
  filterGpsPointsAboveSOGKts: number | undefined;
}): UseSessionDataState {
  const [state, dispatch] = useReducer(stateReducer, { loading: true });
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const client = useApolloClient();

  useEffect(() => {
    let isCanceled = false;

    // If we do not have any data, do the fetch immediately
    // otherwise delay a bit in case the user asks for something else immediately
    const requestDelay = state.trips ? 500 : 0;

    // If there is a fetch scheduled, cancel it before setting a new one.
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    dispatch({ event: "LOADING" });

    // Do not fetch data if we already have the requested period.
    if (
      state.availableDataStart !== undefined &&
      state.availableDataEnd !== undefined &&
      new Date(startTime).getTime() >= state.availableDataStart &&
      new Date(endTime).getTime() <= state.availableDataEnd &&
      state.trips &&
      Array.from(state.trips.keys()).sort().join("-") ===
        boatIds.sort().join("-")
    ) {
      dispatch({ event: "ALREADY_LOADED" });
      return;
    }

    timeoutRef.current = setTimeout(() => {
      const startTimer = performance.now();
      log(`useSessionData(${id}) - Fetching data`);
      const pt = new PerfTimer("useSessionData");
      fetchAndPrepareSessionData(client, id, filterGpsPointsAboveSOGKts)
        .then((data) => {
          pt.mark("fetch");
          if (!isCanceled) {
            console.log(`useSessionData(${id}) - Data loaded`, { data });
            const dispatchStartTimer = performance.now();
            dispatch({ event: "LOADED", payload: data });
            pt.mark("dispatch");
            const sessionDuration =
              new Date(endTime).getTime() - new Date(startTime).getTime();
            const performanceData: AnalyticsProperties = {
              networkTime: data.accumulatedFetchAndMergeTime,
              processingTime: data.accumulatedMakeInteractiveTime,
              elapsedTime: performance.now() - startTimer,
              dispatchDataTime: performance.now() - dispatchStartTimer,
              countBoats: boatIds.length,
              id: id,
              sessionDuration,
              isEditable: editable,
              bytesDownloaded: data.bytesDownloaded ?? 0,
            };
            analytics.event("session-dataloaded", performanceData);
            pt.mark("complete");
            pt.log();
          }
        })
        .catch((error) => {
          console.log(`useSessionData error`, error);
          dispatch({ event: "ERROR", errorMessage: error.toString() });
          analytics.event("session-dataerror", {
            elapsedTime: performance.now() - startTimer,
            countBoats: boatIds.length,
            id: id,
            error: error.toString(),
          });
        });
      timeoutRef.current = undefined;
    }, requestDelay);
    return () => {
      isCanceled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, id, startTime, endTime, boatIds.join("-"), dispatch]);
  return state;
}

function stateReducer(
  state: UseSessionDataState,
  action: StateUpdate
): UseSessionDataState {
  if (action.event === "LOADING") {
    // Keep pre-existing state while loading new data.
    return { ...state, loading: true, error: undefined };
  } else if (action.event === "ALREADY_LOADED") {
    return { ...state, loading: false, error: undefined };
  } else if (action.event === "LOADED") {
    return { ...state, loading: false, error: undefined, ...action.payload };
  } else if (action.event === "ERROR") {
    return { error: action.errorMessage, loading: false };
  }
  return state;
}

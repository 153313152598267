import { makeStyles } from "@material-ui/core";
import chroma from "chroma-js";
import clsx from "clsx";
import DeckGL from "deck.gl";
import mapboxgl from "mapbox-gl";
import { ReactNode, useCallback, useRef, useState } from "react";
import InteractiveMap, { ExtraState, MapRef, ViewState } from "react-map-gl";
import {
  MAPBOX_STYLE_SATELLITE,
  MAPBOX_TOKEN,
  MAPTILER_STYLE_MAP,
  MAPTILER_STYLE_SATELLITE,
} from "~/backend/maps";
import { useResponsiveSmall } from "~/styles/chartedSailsTheme";
import { BoatOverlay } from "../movingboat/BoatOverlay";
import { ReplayBoat } from "../replaycontext/Replay";
import { useReplayContextAndDispatch } from "../replaycontext/ReplayContext";
import { TripLayer, TripLayerColorSegment } from "./TripLayer";
import { tracksConfigForReplay } from "./tracks-config/replayTracksConfig";
import { useMapHoverHighlightLayer } from "./useMapHoverHighlightLayer";
import { useMapPickCallback } from "./useMapPickCallback";
import { useMemoTripLayerData } from "./useMemoTripLayerData";

export type ReplayMapStyle = "map" | "satellite" | "blank" | "satellite-simple";

export const AllTailModes = [
  "solid",
  "fading-tail",
  "speed-gradient",
  "color-segments",
] as const;
export type TailMode = (typeof AllTailModes)[number];

interface IProps {
  className?: string;
  children?: ReactNode;
  mapDecorations?: ReactNode;
  generateColorSegments?: (boat: ReplayBoat) => Array<TripLayerColorSegment>;
  mapStyle?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    // This is a bit redundant but required to work on Safari
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

const getMapTilesUrl = (style: ReplayMapStyle) => {
  switch (style) {
    case "map":
      return MAPTILER_STYLE_MAP;
    case "satellite":
      return MAPBOX_STYLE_SATELLITE;
    case "blank":
      return "";
    case "satellite-simple":
      return MAPTILER_STYLE_SATELLITE;
  }
};

// onClick and onHover are basically the same event - factor them here

export const ReplayMap = ({
  className,
  children,
  mapDecorations,
  generateColorSegments,
  mapStyle,
}: IProps) => {
  const classes = useStyles();

  const { replay, dispatch } = useReplayContextAndDispatch();
  const mapStyleUrl = mapStyle ?? getMapTilesUrl(replay.mapStyle);

  const handleViewStateChange = useCallback(
    ({
      viewState: vs,
      interactionState,
    }: {
      viewState: ViewState;
      interactionState: ExtraState;
    }) => {
      dispatch({
        event: "map-viewstate-change",
        viewState: vs,
        interactionState,
      });
    },
    [dispatch]
  );

  const tracks = tracksConfigForReplay(replay);
  // It's important to make sure the boatLayerDatas do not change too often
  const boatLayerDatas = useMemoTripLayerData(
    replay.boats,
    replay.speedScaleMax,
    generateColorSegments
  );
  // But the layers themselves are cheap and are recreated everytime
  const layers = tracks.map(
    ({
      trackId,
      boatId,
      solidColor,
      visibleStartTime,
      visibleEndTime,
      mode,
    }) => {
      const boatData = boatLayerDatas.get(boatId);
      if (!boatData) {
        return null;
      }

      let color = chroma(solidColor).rgba();
      color[3] = 255;

      return new TripLayer({
        id: trackId,
        data: boatData,
        layerColor:
          mode === "segments"
            ? "colorSegments"
            : mode === "speed"
            ? "speed"
            : color,
        width: 2,
        pickable: true,
        fading:
          mode === "fading-solid"
            ? "backward"
            : mode === "fading-forward"
            ? "forward"
            : false,
        visible: [visibleStartTime, visibleEndTime],
      });
    }
  );

  // Add an extra layer to show the sailing segment when there is one.
  const highlightLayer = useMapHoverHighlightLayer(tracks);
  // We force-deactivate sailing insights on mobile at the moment.
  const isSmall = useResponsiveSmall();
  if (highlightLayer && !isSmall) {
    layers.push(highlightLayer);
  }

  const mapglRef = useRef<MapRef>(null);
  const attributionAdded = useRef(false);
  if (mapglRef.current && !attributionAdded.current) {
    const mapboxMap = mapglRef.current?.getMap();
    mapboxMap?.addControl(new mapboxgl.AttributionControl(), "bottom-left");
    attributionAdded.current = true;
  }

  const deckglRef = useRef<any>();
  // Dont call pick on deckgl before it is loaded or bad things happen!
  const [deckglLoaded, updateDeckglLoaded] = useState(false);

  const handleDeckGlLoaded = useCallback(() => updateDeckglLoaded(true), []);

  const handleClick = useMapPickCallback(
    deckglRef,
    replay,
    dispatch,
    "map-click-track",
    tracks
  );
  const handleHover = useMapPickCallback(
    deckglRef,
    replay,
    dispatch,
    "map-hover-track",
    tracks
  );

  const handleGetCursor = useCallback(
    ({ isDragging }) => {
      if (isDragging) {
        return "move";
      }

      if (replay.hover?.type === "map") {
        return "pointer";
      }

      return "default";
    },
    [replay.hover?.type]
  );

  return (
    <div className={clsx(classes.root, className)}>
      <InteractiveMap
        {...replay.viewState}
        width={"100%"}
        height={"100%"}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        attributionControl={false}
        mapStyle={mapStyleUrl}
        onViewStateChange={handleViewStateChange}
        onHover={deckglLoaded ? handleHover : undefined}
        onClick={deckglLoaded ? handleClick : undefined}
        getCursor={handleGetCursor}
        ref={mapglRef}
        keyboard={false}
        dragRotate={false}
      >
        {mapDecorations}
        <DeckGL
          width={"100%"}
          height={"100%"}
          ref={deckglRef}
          layers={layers}
          viewState={replay.viewState}
          onLoad={handleDeckGlLoaded}
        />
        <BoatOverlay viewport={replay.viewState!} />
        {children}
      </InteractiveMap>
    </div>
  );
};

import { gql } from "@apollo/client";
import { FRAGMENT_BOAT } from "../boat/fragmentBoat";
import { FRAGMENT_SAILINGMARK } from "../mark/fragmentSailingMark";
import { FRAGMENT_MEDIA } from "../media/fragmentMedia";
import { FRAGMENT_SEGMENT } from "./fragmentSegment";
import { FRAGMENT_SESSION_EVENT } from "./fragmentSessionEvent";

export const FRAGMENT_SESSION = gql`
  fragment SessionInfo on Session {
    id
    title
    startTime
    endTime
    earliestData
    latestData
    permissions {
      edit
      share
      addBoat
    }
    configuration {
      filterGpsPointsAboveSOGKts
    }
    mapOrientation
    marks {
      ...SailingMark
    }
    boats {
      ...Boat
    }
    segments {
      ...Segment
    }
    outstandingInvites {
      creationDate
      sentToEmail
      role
    }
    owner {
      id
      name
      email
    }
    sharedWith {
      role
      user {
        id
        name
        email
      }
    }
    events {
      ...SessionEvent
    }
    media {
      ...Media
    }
  }
  ${FRAGMENT_BOAT}
  ${FRAGMENT_SEGMENT}
  ${FRAGMENT_SAILINGMARK}
  ${FRAGMENT_SESSION_EVENT}
  ${FRAGMENT_MEDIA}
`;

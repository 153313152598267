import { makeStyles, MenuItem, Select } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { useCallback, useState } from "react";
import { carbon } from "~/styles/chartedsailsColors";
import { BoatSymbol } from "../dynamicicons/BoatSymbol";
import { ReplayBoat } from "../replay/replaycontext/Replay";

const useStyles = makeStyles((theme) => ({
  root: {
    "&::before": {
      content: "none",
    },
    "&::after": {
      content: "none",
    },
    "& .MuiSelect-root": {
      fontSize: 14,
      lineHeight: "20px",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      // Make space for the arrow
      paddingRight: theme.spacing(5),
      "&:focus": {
        background: "none",
      },
    },
    "& .MuiSelect-icon": {
      top: "auto",
      right: 8,
      fill: carbon["400"],
      height: 24,
      width: 24,
      transition: theme.transitions.create("transform"),
      "&.MuiSelect-iconOpen": {
        transform: "scale3d(1, -1, 1)",
      },
    },
  },
  open: {},
  boatSelectMenu: {
    "& .MuiPaper-root": {
      "& .MuiList-root": {
        minWidth: 200,
        "& .Mui-selected": {
          backgroundColor: "inherit",
        },
      },
      boxShadow: `inset 0px 0px 0px 1px ${carbon[200]}, 0px 1px 12px rgba(0, 0, 0, 0.14)`,
      borderRadius: `0px 8px 8px 8px`,
      // Make popup overlap with bottom border of input
      marginTop: -1,
    },
  },
  boatItem: {
    width: "100%",
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    "& span": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      marginRight: 6,
    },
  },
  boatIcon: {
    flexShrink: 0,
    marginRight: 6,
    height: 20,
    width: 20,
  },
  boatCheckmark: {
    marginLeft: "auto",
    marginRight: -8,
    height: 16,
    color: theme.palette.primary.main,
  },
}));

interface IProps {
  className?: string;
  boats: ReplayBoat[];
  selectedBoatIds: string[];
  onSelectionChange: (selectedBoatIds: string[]) => void;
}

export const BoatFilter = ({
  className,
  boats,
  selectedBoatIds,
  onSelectionChange,
}: IProps) => {
  const classes = useStyles();

  const [open, updateOpen] = useState(false);
  const handleBoatChange = useCallback(
    (e) => {
      const newSelection = e.target.value as string[];
      if (newSelection.length > 1 && newSelection[0] === "all") {
        newSelection.shift();
        onSelectionChange?.(newSelection);
      } else if (
        newSelection.findIndex((s) => s === "all") !== -1 ||
        newSelection.length === 0
      ) {
        onSelectionChange?.(boats.map((b) => b.id));
      } else {
        onSelectionChange?.(e.target.value);
      }
    },
    [boats, onSelectionChange]
  );
  const itemWithCheckmarks =
    selectedBoatIds.length === boats.length ? ["all"] : selectedBoatIds;

  return (
    <Select
      className={clsx(
        classes.root,
        open && classes.open,
        open && "cs-bf-open",
        className
      )}
      value={
        selectedBoatIds.length === boats.length ? ["all"] : selectedBoatIds
      }
      renderValue={(v) => {
        const selected = v as string[];
        if (selected.length === 1 && selected[0] === "all") {
          return "All Boats";
        }
        if (selected.length === 1) {
          return boats.find((b) => b.id === selected[0])?.name;
        }
        return `${selected.length} boats`;
      }}
      multiple
      open={open}
      onOpen={() => updateOpen(true)}
      onClose={() => updateOpen(false)}
      onChange={handleBoatChange}
      IconComponent={ExpandMoreIcon}
      MenuProps={{
        className: classes.boatSelectMenu,
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
        transformOrigin: { vertical: "top", horizontal: "left" },
        getContentAnchorEl: null,
      }}
    >
      {boats.length > 1 && (
        <MenuItem value="all" className={classes.boatItem}>
          All Boats
          {itemWithCheckmarks.length === 1 &&
            itemWithCheckmarks[0] === "all" && (
              <CheckIcon className={classes.boatCheckmark} />
            )}
        </MenuItem>
      )}
      {boats.map((b) => (
        <MenuItem value={b.id} key={b.id}>
          <div className={classes.boatItem}>
            <BoatSymbol color={b.color} className={classes.boatIcon} />
            <span>{b.name}</span>
            {itemWithCheckmarks.find((id) => id === b.id) !== undefined ? (
              <CheckIcon className={classes.boatCheckmark} />
            ) : null}
          </div>
        </MenuItem>
      ))}
    </Select>
  );
};
